body {
    background: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/home/agi/Downloads/my-dashboard/src/assets/AdobeStock_504247272 (1).avif') no-repeat center center;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}

.container {
    background: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 800px;
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
}

.container:hover {
    transform: scale(1.02);
    background-color: rgba(255, 255, 255, 0.95);
}

h1 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    color: #000;
}

input,
button {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

input {
    background-color: #f0f0f0;
    color: #000;
    outline: none;
    transition: background-color 0.3s, transform 0.3s;
}

input::placeholder {
    color: #888;
}

input:focus {
    background-color: #e0e0e0;
    transform: scale(1.02);
}

button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

button:active {
    background-color: #004494;
}

.plan-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.plan-box {
    background-color: rgba(240, 240, 240, 0.9);
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    text-align: center;
    color: #000;
}

.plan-box:hover {
    transform: scale(1.05);
    background-color: rgba(224, 224, 224, 0.9);
}

.plan-box.selected {
    border: 2px solid #007bff;
    transform: scale(1.05);
}

.plan-box h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}

.plan-box p {
    font-size: 16px;
    margin: 0;
    color: #555;
}

@media (max-width: 768px) {
    .container {
        width: 90%;
        padding: 30px;
    }
    .plan-container {
        flex-direction: column;
        align-items: center;
    }
    .plan-box {
        width: 80%;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .container {
        width: 95%;
        padding: 20px;
    }
    input,
    button {
        padding: 12px;
        margin: 10px 0;
        font-size: 14px;
    }
}

.image-container {
    margin-bottom: 20px;
}

.image-container img {
    width: 100%;
    max-width: 100px;
    border-radius: 50%;
}