.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #0056b3;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #004494;
}


/* Responsive Design */

@media (max-width: 600px) {
    .login-form {
        padding: 15px;
    }
}